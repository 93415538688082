<template>
  <div class="times">
    <div class="days">
      <span>{{ sayı }}</span>
    </div>
    <div class="date">
      <p class="month">{{ ay }}</p>
      <p class="day">{{ gun }}</p>
    </div>
    <div class="clock">
      <p>{{ saat }} : {{ dakika }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      guncelGun: "",
      sayı: "",
      saat: "",
      dakika: "",
      ay: "",
      gun: "",
      gunler: [
        "Pazartesi",
        "Salı",
        "Çarşamba",
        "Perşembe",
        "Cuma",
        "Cumartesi",
        "Pazar",
      ],
      aylar: [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
      ],
      timer: null,
    };
  },
  created() {
    this.saatAl();
    this.timer = setInterval(this.saatAl, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer); // Komponent yok edilmeden önce setInterval'i sıfırlıyoruz
  },
  methods: {
    saatAl: function () {
      this.guncelGun = new Date();
      this.sayı = this.guncelGun.getDate();
      this.gun = this.gunler[this.guncelGun.getDay().toLocaleString("tr-TR")];
      this.saat = this.guncelGun.getHours()
      this.saat = this.saat < 10 ? "0" + this.saat : this.saat
      this.dakika = this.guncelGun.getMinutes();
      this.dakika = this.dakika < 10 ? "0" + this.dakika : this.dakika
      this.ay = this.aylar[this.guncelGun.getMonth().toLocaleString("tr-TR")];
    },
    
  },
};
</script>

<style>
.times {
  display: flex;
  align-items: center;
  justify-content: center;
}
.times .days {
  font-size: 34px;
  font-weight: bold;
  color: #666666;
}
.times .date {
  font-size: 14px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.times .date .month {
  color: #999999;
  position: relative;
  top: 3px;
}
.times .date .day {
  position: relative;
  bottom: 2px;
  color: #666666;
}
.times .clock {
  font-size: 14px;
  margin-left: 17px;
  color: #999999;
}
</style>
